// table

// table1
.table1{
  table,th,td{
    border: 1px solid #ddd;
  }
  table{
    border-collapse: collapse;
    margin: 0 auto;
    width: 100%;
    tr{
      th{
        text-align: center;
        padding: 10px 0;
        font-weight: 400;
      }
      td{
      }
      .date{
        width: 100px;
        padding: 10px 12px;
        font-weight: 400;
      }
    }

    .form__radio{
      margin: 0 auto;
    }
  }
}

// table2
.table2{
  max-width: 834px;
  margin: 0 auto 103px;
  table,th,td{
    border: 1px solid #ddd;
  }
  table{
    border-collapse: collapse;
    margin: 0 auto;
    width: 100%;
  }

  tr{
    &.filter{
      //background: rgba(204, 204, 204, 0.8);
      background: #ccc;
      opacity: 0.6;
    }
    th{
      padding: 10px 0;
      text-align: center;
    }
    td{
      padding: 10px 0;
      text-align: center;
      &.date{
        padding: 10px 12px;
        width: 120px;
      }
      .count{
        position: absolute;
        top: -3px;
        color: #fff;
        font-size: 1.2rem;
        font-weight: bold;
        background: #777;
        padding: 2px 7px;
        text-align: center;
        border-radius: 9px;
        margin-left: 20px;
        @include mediaquery(){
          margin-left: 3px;
          padding: 2px 5px;
        }
      }
      .seat{
        position: absolute;
        top: -3px;
        font-size: 1.2rem;
        font-weight: bold;
        padding: 2px 7px;
        text-align: center;
        border-radius: 9px;
        margin-left: 45px;
        @include mediaquery(){
          margin-left: 3px;
          padding: 2px 5px;
        }
      }
    }

    .date{
      font-weight: 400;
    }

    .form__label{
      margin-bottom: 0;
      display: inline;
      .item{
        margin: 0 auto;
        display: inline-block;
        padding: 0;
      }
    }
  }
}

// table3
.table3{
  margin-bottom: 103px;
  table,th,td{
    border: 1px solid #ddd;
  }
  table{
    border-collapse: collapse;
    margin: 0 auto;
    width: 100%;
    @include mediaquery(){
      word-break: break-all;
    }
    tr{
      th{
        word-break: keep-all;
        padding: 10px 12px;
        text-align: center;
        @include mediaquery(){
          word-break: break-all;
        }
      }
      td{
        padding: 10px 12px;
      }
    }
  }
}