/* copied from cakephp3 */

/* copied from source/webroot/css/base.css */
// ul.pagination{
//   display:block;
//   margin-left:-0.3125rem;
//   min-height:1.5rem
// }
//
// ul.pagination li{
//   color:#222;
//   font-size:0.875rem;
//   height:1.5rem;
//   margin-left:0.3125rem
// }
//
// ul.pagination li a,ul.pagination li button{
//   border-radius:3px;
//   transition:background-color 300ms ease-out;
//   background:none;
//   color:#999;
//   display:block;
//   font-size:1em;
//   font-weight:normal;
//   line-height:inherit;
//   padding:0.0625rem 0.625rem 0.0625rem
// }
//
// ul.pagination li:hover a,ul.pagination li a:focus,ul.pagination li:hover button,ul.pagination li button:focus{
//   background:#e6e6e6
// }
//
// ul.pagination li.unavailable a,ul.pagination li.unavailable button{
//   cursor:default;
//   color:#999
// }
//
// ul.pagination li.unavailable:hover a,ul.pagination li.unavailable a:focus,ul.pagination li.unavailable:hover button,ul.pagination li.unavailable button:focus{
//   background:transparent
// }
//
// ul.pagination li.current a,ul.pagination li.current button{
//   background:#008CBA;
//   color:#fff;
//   cursor:default;
//   font-weight:bold
// }
//
// ul.pagination li.current a:hover,ul.pagination li.current a:focus,ul.pagination li.current button:hover,ul.pagination li.current button:focus{
//   background:#008CBA
// }
//
// ul.pagination li{
//   display:block;
//   float:left
// }
//
// .pagination-centered{
//   text-align:center
// }
//
// .pagination-centered ul.pagination li{
//   display:inline-block;
//   float:none
// }
//
// /* source/webroot/css/style.css */
// ul.pagination li a {
//   color: rgba(0, 0 ,0 , 0.54);
// }
//
// ul.pagination li.active a {
//   background-color: #008EEE;
//   color: #FFF;
//   font-weight: bold;
//   cursor: default;
// }
// ul.pagination .disabled:hover a {
//   background: none;
// }
//
// .paginator {
//   text-align: center;
// }
//
// .paginator ul.pagination li {
//   float: none;
//   display: inline-block;
// }
//
// .paginator p {
//   text-align: right;
//   color: rgba(0, 0 ,0 , 0.54);
// }
//
// /* original */
// ul.pagination li{
//   font-size: 1.575rem;
// }
.weekLink {
  padding: 3rem 1.25rem 1rem;
}

.pagination {
    display: -ms-flexbox;
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: .25rem;
    .page-link {
        position: relative;
        display: block;
        padding: 1.25rem;
        margin-left: -1px;
        line-height: 1.25;
        color: #007bff;
        background-color: #fff;
        border: 1px solid #dee2e6;
    }
    .rounded-pill {
        border-radius: 50rem!important;
    }
    .text-muted {
        color: #6c757d!important;
    }
    .btn.disabled, .btn:disabled {
        opacity: .65;
    }
    a.btn.disabled, fieldset:disabled a.btn {
        pointer-events: none;
    }
}
