// heading1
.heading1{
  font-size: 2.5rem;
  margin-bottom: 54px;
}

// heading2
.heading2{
  font-size: 2.0rem;
  margin-bottom: 21px;
}

// heading3
.heading3{
  font-size: 1.75rem;
}

// heading4
.heading4{
  font-size: 1.6rem;
  font-weight: bold;
  margin-bottom: 16px;
}
