// btn1
.btn1{
  display: block;
  width: 112px;
  max-width: 100%;
  text-align: center;
  border-radius: 4px;
  padding: 10px 0;
  border: none;
  @include mediaquery(){
    //width: auto;
    //max-width: 112px;
  }
}

// btn2
.btn2{
  display: block;
  width: 380px;
  max-width: 100%;
  text-align: center;
  border-radius: 4px;
  padding: 17px;
  border: none;
  @include mediaquery(){
    width: auto;
    max-width: 380px;
  }
}

//btn3
.btn3{
  display: block;
  width: 123px;
  max-width: 100%;
  text-align: center;
  border-radius: 4px;
  padding: 17px;
  border: none;
  @include mediaquery(){
    //width: auto;
    min-width: 123px;
  }
}

// color
.btn{
  &--blue{
    background: #1C7CD5;
    color: #fff;
  }
  &--gray{
    background: #E6E6E6;
    color: #373A3C;
  }
}