@charset "utf-8";

/*--------------------structure setting---------------------*/
/*----------html----------*/
html {
	&.no-js {
	}
	&.lt-ie9 {
	}
	//IE9未満
	&.lt-ie8 {
	}
	//IE8未満
	&.lt-ie7 {
	}
	//IE7未満
}

/*----------body----------*/
body {
	&.admin{
		padding-top: 60px;
	}
}

/*----------pageWrapper----------*/
.pageWrapper {}


/*----------pageHeader----------*/
.pageHeader {
	display: flex;
	align-items: center;
	background: #ECEEEF;
	padding: 8px 13px;
	z-index: 99;
	margin-bottom: 43px;
	@include mediaquery(){
		flex-direction: column;
		align-items: flex-start;
		margin-bottom: 26px;
	}


	.admin &{
		position: fixed;
		top: 0;
		width: 100%;
	}

	.pageHeader__col{
		margin-right: 48px;
		@include mediaquery(){
			margin-right: 0;
			// margin-bottom: 10px;
		}
		&:last-child{
			margin-right: 0;
			@include mediaquery(){
				margin-bottom: 0;
			}
		}
		.header-logo {
			width: 160px;
	    display: block;
		}
	}
}

/* nav */
.nav{
}

.navList{
	display: flex;
	.navList__item{
		margin-right: 20px;
		&:last-child{
			margin-right: 0;
		}
		&.active{

		}
	}
}


/*----------pageContent----------*/
.pageContent {
	display: block;
	max-width: 1000px;
	width: 90%;
	margin: 0 auto;

	.admin &{
		padding-top: 40px;
		padding-bottom: 40px;
		@include mediaquery(){
			padding-top: 90px;
		}
	}

	.pageContent__inner {
		// background-color: #ccc;
	}
}

.section{
	margin-bottom: 39px;
}

// 中央寄せ
.margin_center{
	margin: 0 auto;
}
.align_center{
	text-align: center;
}

// 左寄せ
.margin_left{
	margin: 0 auto 0 0;
}

.align_left{
	text-align: left;
}

// 右寄せ
.margin_right{
	margin: 0 0 0 auto;
}
.align_right{
	text-align: right;
}


/*----------pageFooter----------*/
.pageFooter {
}

// text
.bold{
	font-weight: bold;
}
