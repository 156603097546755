@charset "utf-8";

/*--------------------basic settings---------------------*/
html {
  font-size: $base_fontsize;
  //height: 100%;
  min-height: 100%;
  //font-family: "rounded-mplus-1p";
}

body {
  //font-family: 'rounded-mplus-1p';
  font-size: 1.6rem;
  font-weight: 400;
  //color: #fff;
  line-height: $default_lineheight;
  //letter-spacing: $default_latterspacing;
  letter-spacing: -0.001em;
  text-align: left;
  //background-color: #fff;
  //-webkit-text-size-adjust: 100%;
  margin: 0 auto;
  height: 100%;
  max-width: 100%;
  min-width: initial;
  //min-height: 100%;
  position: relative;
  // background: #00CC8B;
  //background: -webkit-linear-gradient(bottom, #a9c51f 0%, #0c8 100%);
  //background: -o-linear-gradient(bottom, #a9c51f 0%, #0c8 100%);
  //background: linear-gradient(to top, #a9c51f 0%, #0c8 100%);
  //overflow-x: hidden;

  @include mediaquery() {
    // font-size: 1.4rem;
    padding-top: 0;
  }

  // print
  // @media print {
  //   line-height: 1.5;
  //   height: auto;
  // }

  // js-toggle-active
  // &.js-toggle-active{
  //   overflow: hidden;
  // }
}

@font-face {
  //font-family: 'rounded-mplus-1p';
  //src: url('/fonts/rounded-mplus-1p-regular.eot');
  //src: url('/fonts/rounded-mplus-1p-regular.eot?#iefix') format('embedded-opentype'),
  //url('/fonts/rounded-mplus-1p-regular.woff') format('woff'),
  //url('/fonts/rounded-mplus-1p-regular.ttf') format('truetype');
}

* {
  box-sizing: border-box;
}

/*-----heading-----*/
h1, h2, h3, h4, h5, h6 {
  font-size: 1.6rem;
  margin: 0;
  font-weight: 400;
}

h1, h2, h3, h4, h5, h6, dt, label {
  // -webkit-font-smoothing: antialiased;
  // text-rendering: optimizelegibility;
}

/*-----paragraph-----*/
p {
  margin: 0 0 20px;
  font-size: 1.6rem;
}

/*-----lists-----*/
ul, ol, dl, dl dt, dl dd {
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 1.6rem;
}

ul, ol {
  li {
    //margin-bottom: 0.5em;
  }
}

dl {
  dt {
    font-weight: bold;

    &:not(:first-child) {
      @include mediaquery("max-width:" + $landscape_width) {
        margin-top: $base_margin * 4 - $line_margin * 2;
      }
      @include mediaquery("min-width:" + $pc_min-width) {
        margin-top: $base_margin * 6 - $line_margin * 2;
      }
    }
  }

  // @include mediaquery("max-width:" + $landscape_width) {
  //   dd {
  //     margin-bottom: $base_margin * 4 - $line_margin * 2;
  //   }
  // }
  // @include mediaquery("min-width:" + $pc_min-width) {
  //   dd {
  //     margin-bottom: $base_margin * 6 - $line_margin * 2;
  //   }
  // }
}

br {
  letter-spacing: normal;
}

/*-----table-----*/
table {
  border-collapse: separate;
  border-spacing: 0;
  tr{
    td{
      font-size: 1.6rem;
    }
  }
}

/*-----horizontal line-----*/
hr {
  border: 0;
  border-top: 1px solid #ccc;
  display: block;
  margin: 10px 0 10px;
  padding: 0;
  height: 0;
}

/*-----quote-----*/
blockquote, q {
  quotes: none;

  &:before, &:after {
    content: '';
    content: none;
  }
}

blockquote {
  margin: 1.5em 1em;
}

/*-----anchor-----*/

a {
  // font-weight: 400;
  //color: #fffb00;
  text-decoration: none;
  //word-break: break-all;
  //transition: .5s;

  &:visited {
    //color: #fffb00;
  }
  &:hover {
    //text-decoration: underline;
    //color: #424846;
    cursor: pointer;
    opacity: 0.7;
  }
  &:active {
  }

  &[target="_blank"]:not(.no-icon):not(.forIE) {
    &::after {
      font-family: "rn-iconfont";
      font-size: 1.0rem;
      vertical-align: 1px;
      text-indent: 0;
      content: "\E00E";
      display: inline-block;
      margin-right: 0.5em;
      margin-left: 0.5em;
    }
  }

  // rn-iconfont
  // .icon-external {
  //   font-size: 1rem;
  //   margin-left: 1em;
  // }

  // print
  @media print {
    &[target="_blank"]:not(.no-icon) {
      &::after {
        content: none;
      }
    }
  }

  html[lang="en"] & {
    word-break: normal;
  }
}

/*-----strong/emphasis-----*/
strong, b {
  font-weight: bold;
}

em {
  font-style: normal;
}

/*-----mark-----
mark{
	background-color: #ff9;
	font-style: italic;
	font-weight: bold;
	color: #000;
}*/

/*-----insert/delete-----
ins{
	background-color: #ff9;
	color: #000;
	text-decoration: none;
}
del{ text-decoration: line-through;}*/

/*-----abbreviation/acronym/definition-----*/
abbr[title], acronym[title], dfn[title] {
  text-decoration: underline;
  border-bottom: 0;
  cursor: help;
}

/*-----preformatted text-----*/
code, kbd, pre, samp {
  font-size: 1.6rem;
}

pre {
  // border: 1px solid #e0e0e0;
  background-color: #f2f2f2;
  box-sizing: border-box;
  margin: 0;
  padding: fitText() 2em;
}

/*-----small-----*/
small {
  font-size: 1.6rem;
}

/*-----address-----*/
address {
  font-style: normal;
}

/*-----image-----*/
img {
  font-family: sans-serif;
  line-height: 0;
  vertical-align: top;
  border: none;
  -ms-interpolation-mode: bicubic;
  max-width: 100%;
}

/*-----figure-----*/
figure {
  margin: 0;
}

/*-----media-----*/
embed,
iframe,
object,
video {
  max-width: 100%;
}

/*-----form parts-----*/
fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

label {
}

input[type="text"],
input[type="tel"],
input[type="email"],
input[type="password"],
input[type="search"],
input[type="checkbox"],
input[type="radio"],
input[type="submit"],
input[type="image"],
input[type="button"],
input[type="reset"],
textarea,
select,
button {
  font-family: $fontfamily;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: $default_lineheight;
  vertical-align: middle;
  margin: fitText() 0;
}

input[type="text"], input[type="search"],
textarea {
  // -webkit-appearance: textfield;
}

input[type="file"] {
  font: static;
}

textarea{
  width: 100%;
}

select {
}
