@charset "utf-8";


//-----variables configuration-----
// Meiryo,ヒラギノ角ゴ Pro W3, Hiragino Kaku Gothic Pro, sans-serif;
$fontfamily: 'Rounded Mplus 1c', \30d2 \30e9 \30ae \30ce \89d2 \30b4 \20 \50 \72 \6f \20 \57 \33 , Hiragino Kaku Gothic Pro, Meiryo, sans-serif;
$fontfamily2: Century Gothic, Hiragino Kaku Gothic Pro, Meiryo, sans-serif;

$color: #111;
$base_fontsize: 10px;
$default_fontsize: 14px;
$default_lineheight: (19 / 16);
$default_latterspacing: 0;
$line_padding: ($default_lineheight - 1) / 2 + em;
$line_margin: round(($default_fontsize * $default_lineheight - $default_fontsize) / 2);

$mobile_width: 414px;
$portrait_width: $mobile_width;
$landscape_width: 768px;

$pc_width: 1440px;
$pc_min-width: $landscape_width + 1;

$mediaquery_condition: "max-width:" + $landscape_width;

$theme_color: #0086cc;	//link color
// $theme_color2: #025fae;
$base_margin: 8px;
$sp_margin: 5.33vw;
$opacity: 0.75;

$prefix: -webkit-, -moz-, -ms-, -o-, '';

$img_dir: "/assets/images/";


//-----functions-----
//bgPosV
//get background-postion vertical value
@function bgPosV($fontsize, $imgheight, $lineheight:$default_lineheight){
	@return (($fontsize * $lineheight - $imgheight) / 2)/$fontsize + em;
}

//retinaImg
//get image size for retina
@function retinaImg($value){
	@return round($value / 2);
}

//fitText
//inline block to fit the text
@function fitText($fontsize:$default_fontsize, $lineheight:$default_lineheight){
	@return ($fontsize * $lineheight - $fontsize) / 2;
}

//excludePrefix
@function excludePrefix($excludes){
	@if($excludes != false){
		$tmp:();
		@each $v in $prefix{
			$n:index($excludes, $v);
			@if($n==false){
				$tmp:join($tmp, $v);
			}
		}
		@return $tmp;
	}@else{
		@return $prefix;
	}
}


//-----mixins-----
//joinPrefix
@mixin joinPrefix($property, $value, $excludes){
	@if($excludes != false){
		$tmp:();
		@each $v in $prefix{
			$n:index($excludes, $v);
			@if($n==false){
				$tmp:join($tmp, $v);
			}
		}
		@each $v in $tmp{
			#{$v + unquote($property)}:unquote($value);
		}
	}@else{
		@each $v in $prefix{
			#{$v + unquote($property)}:unquote($value);
		}
	}
}

//box_shadow
@mixin box_shadow($value, $excludes:false){
	$use_prefix:excludePrefix($excludes);
	@each $v in $use_prefix{
		#{$v}box-shadow:unquote($value);
	}
}

//transition
@mixin transition($value, $excludes:false){
	$use_prefix:excludePrefix($excludes);
	@each $v in $use_prefix{
		#{$v}transition:unquote($value);
	}
}

//transform_style
@mixin transform_style($value, $excludes:false){
	$use_prefix:excludePrefix($excludes);
	@each $v in $use_prefix{
		#{$v}transform-style:unquote($value);
	}
}

//display_flex
@mixin display_flex($value:"flex", $excludes:false){
	$use_prefix:excludePrefix($excludes);
	@each $v in $use_prefix{
		display:#{$v}unquote($value);
	}
}

//box_flex
@mixin box_flex($value, $excludes:false){
	$use_prefix:excludePrefix($excludes);
	@each $v in $use_prefix{
		#{$v}box-flex:unquote($value);
	}
}

//box_sizing
@mixin box_sizing($value, $excludes:false){
	$use_prefix:excludePrefix($excludes);
	@each $v in $use_prefix{
		#{$v}box-sizing:unquote($value);
	}
}

//bg_size
@mixin bg_size($value, $excludes:false){
	$use_prefix:excludePrefix($excludes);
	@each $v in $use_prefix{
		#{$v}background-size:unquote($value);
	}
}

//mediaquery
@mixin mediaquery($condition:$mediaquery_condition) {
	@media all and ($condition){
		@content;
	}
}

//hoverOpacity
@mixin hoverOpacity(){
	transition: opacity .25s;
	opacity: 1;

	&:hover{
		opacity: $opacity;
	}
}

// clearfix
@mixin clearfix(){
	&::after {
		clear: both;
		content: "";
		display: block;
	}
}

//-----add-ons-----
/*clearfix*/
.clearfix{
	@include clearfix();
}

.clearfixLegacy{
	_overflow:visible;
	_height:1px;
	zoom:1;

	&:after{
		content:".";
		display:block;
		visibility:hidden;
		clear:both;
		height:0px;
		font-size:0px;
	}
}

//input text style
@mixin input_text_style(){
	// @include appearance(none);
	font-size: 1.6rem;
	border: 1px solid #c1c1c1;
	// border-radius: 5px;
	background-color: #fff;
	// @include box-shadow(1px 2px 4px rgba(0, 0, 0, .1) inset);
	box-sizing: border-box;
	padding: 2px 4px;
}

//background propaties with background-size
@mixin bg($url, $position, $repeat, $size){
	background-image:$url;
	background-position:$position;
	background-repeat:$repeat;
	background-size:$size;
}

//exchangePath
// @function exchangePath($local, $web){
// 	@if $output == 'web' {
// 		@return $web;
// 	} @else {
// 		@return $local;
// 	}
// }

//lineMargin
@function lineMargin($lineheight:$default_lineheight, $fontssize:$default_fontsize){
	@return round(($fontssize * $lineheight - $fontssize) / 2);
}

//vwCalc
@function vwCalc($value, $width:$landscape_width){
	$width: $width / ($width * 0 + 1);
	@return ($value / $width) * 100vw;
}
