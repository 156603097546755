// list
// scheduleList
.scheduleList{
  margin-bottom: 40px;
  li{
    font-size: 1.4rem;
    margin-bottom: 15px;
    &:last-child{
      margin-bottom: 0;
    }
  }
}