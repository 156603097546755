// listGroup
.listGroup{
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;

    &-item {
      position: relative;
      display: block;
      padding: 1.75rem 1.25rem;
      margin-bottom: -1px;
      background-color: #fff;
      border: 1px solid rgba(0,0,0,.125);
      &:first-child {
          border-top-left-radius: .25rem;
          border-top-right-radius: .25rem;
      }
    }

}
.listGroup-flush {
  .listGroup-item {
      border-right: 0;
      border-left: 0;
      border-radius: 0;
      &:first-child {
          border-top: 0;
      }
      &:last-child {
          border-bottom: 0;
      }
  }
}
