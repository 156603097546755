/* flow */
.flow{
  display: flex;
  justify-content: center;
  .flow__item{
    background: #ccc;
    padding: 14px 22px;
    position: relative;
    z-index: 1;
    font-size: 1.4rem;
    &.active{
      background: #1C7CD5;
      color: #fff;
      z-index: 10;
      padding-right: 13px;
      &:after{
        border-left: 23px solid #1C7CD5;
      }

      & + .flow__item{
        padding-left: 37px;
      }
    }

    &:after{
      position: absolute;
      display: block;
      content: '';
      top: 50%;
      right: -23px;
      transform: translateY(-50%);
      border-top: 23px solid transparent;
      border-bottom: 23px solid transparent;
      border-left: 23px solid #ccc;
    }

    &:last-child{
      &:after{
        display: none;
      }
    }
  }
}
