a {
  color: #1C7CD5;
}

.weekLink {
  ul {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
}

.monthLink {
  ul {
    display: flex;
    justify-content: space-between;
    margin-bottom: 17px;
  }
}
