// row
.row{
  display: flex;
  &.row--left{
    justify-content: flex-start;
  }

  &.row--right{
    justify-content: flex-end;
  }

  &.row--ends{
    justify-content: space-around;
  }

  &.row--center{
    justify-content: center;
  }

  .row__item{
    margin-right: 10px;
    &:last-child{
      margin-right: 0;
    }
  }
}