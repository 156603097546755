.form {
  margin-bottom: 50px;
  .form__item {
    margin-bottom: 50px;
    label {
      font-weight: bold;
      display: block;
      margin-bottom: 14px;
    }
  }

  .input_text {
    width: 100%;
    height: 55px;
    border-radius: 4px;
    border: 1px solid #7E7E7E;
    padding: 0 10px;
    font-size: 1.6rem;
  }

  .form__label {
    font-weight: normal;
    font-size: 1.5rem;
    margin-bottom: 21px;
    display: flex;
    align-items: center;
    position: relative;

    //checkbox
    .checkbox {
      display: none;
      &:checked {
        & + .item {
          position: relative;
          &:before {
            background: #1C7CD5;
          }
          &:after {
            content: '';
            position: absolute;
            top: 1px;
            left: 5px;
            width: 3px;
            height: 7px;
            border-right: 2px solid #fff;
            border-bottom: 2px solid #fff;
            transform: rotate(45deg);
          }
        }
      }
    }
    .item {
      width: 15px;
      height: 15px;
      position: relative;
      padding-left: 20px;
      &:before {
        content: '';
        position: absolute;
        width: 15px;
        height: 15px;
        top: 0;
        left: 0;
        background: #ddd;
        border-radius: 4px;
      }
    }
  }

  // select
  .form__select {
    position: relative;
    max-width: 225px;
    //矢印
    &:before {
      position: absolute;
      top: 50%;
      right: 0.9em;
      transform: translateY(-50%);
      width: 0;
      height: 0;
      padding: 0;
      content: '';
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-top: 6px solid #707070;
    }
    //縦棒
    &:after {
      position: absolute;
      top: 0;
      right: 2.5em;
      bottom: 0;
      width: 1px;
      content: '';
      border-left: 1px solid #E4E4E4;
    }
    select {
      width: 100%;
      cursor: pointer;
      text-indent: 0.01px;
      text-overflow: ellipsis;
      outline: none;
      background: transparent;
      box-shadow: none;
      appearance: none;
      position: relative;
      border: 1px solid #E4E4E4;
      border-radius: 2px;
      padding: 8px 38px 8px 8px;
      color: #000;
      margin: 0;
      &:-ms-expand {
        display: none;
      }
      option {

      }
    }
  }

  // radio
  .form__radio {
    margin-bottom: 0;
    position: relative;
    width: 15px;
    height: 15px;
    .radio {
      display: none;
      &:checked {
        & + span {
          background: #000;
          width: 10px;
          height: 10px;
          &:before {
            background: #1C7CD5;
            border: 1px solid #1C7CD5;
          }
          &:after {
            content: '';
            position: absolute;
            //top: 50%;
            //left: 50%;
            //transform: translate(-50%, -50%);
            width: 7px;
            height: 7px;
            background: #fff;
            border-radius: 50%;
            top: -12px;
            left: 5px;
          }
        }
      }
    }
    span {
      position: relative;
      &:before {
        background: #fff;
        border: 1px solid #95989A;
        content: '';
        display: block;
        width: 15px;
        height: 15px;
        border-radius: 50%;
      }
    }

  }

  .form__column2 {
    display: flex;
    flex-wrap: wrap;
    .form__col {
      width: 50%;
      box-sizing: border-box;
    }
  }

  //確認画面
  &.form--confirm {
    margin-bottom: 54px;
    .form__item {
      margin-bottom: 30px;
      .label{
        font-weight: bold;
        display: block;
        margin-bottom: 14px;
      }
      //.content{
      //
      //}
      //p {
      //  margin-bottom: 0;
      //}
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .required {
    font-size: 1.4rem;
    color: red;
  }

  .form__notice {
    font-size: 1.4rem;
    color: red;
    margin-top: -10px;
    margin-bottom: 14px;
    @include mediaquery() {
      margin-top: -7px;
      font-size: 1.3rem;
    }
  }

  .form__cant_reserve {
    display: block;
    text-align: center;
  }
}