// msg

// successMsg
.successMsg, .success{
  position: relative;
  margin: 0 auto;
  background: #E2EED8;
  color: #4B713D;
  padding: 15px 23px;
  border-radius: 3px;
  max-width: 980px;
  margin-bottom: 53px;
}

// errorMsg
.errorMsg, .error-message{
  position: relative;
  margin: 0 auto;
  background: #FADBDA;
  color: #FA2019;
  padding: 15px 23px;
  border-radius: 3px;
  max-width: 980px;
  margin-top: 15px;
  margin-bottom: 53px;
}

//closeBtn
.closeBtn{
  position: absolute;
  width: 12px;
  height: 12px;
  display: inline-block;
  right: 13px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  &:before, &:after{
    display: block;
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 84%;
    height: 16%;
    margin: -8% 0 0 -42%;
    background: #BEC8B7;
  }
  &:before{
    transform: rotate(45deg);
  }
  &:after{
    transform: rotate(-45deg);
  }
}
