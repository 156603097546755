.description {
  margin-bottom: 50px;
}

.description__row {
  display: flex;
  margin-bottom: 8px;
}

.description__item {
  min-width: 70px;
  margin-right: 10px;
}