.contactBlock{
  border:  1px solid #707070;
  padding: 7px;
  margin-bottom: 20px;

  p{
    margin-bottom: 5px;
    &:last-child{
      margin-bottom: 0;
    }
  }
}